import React from 'react'

import BottomNav from 'components/BottomNav'
import PrivacyPolicy from 'components/PrivacyPolicy'
import SiteHeadMetadata from 'components/SiteHeadMetadata'

export const PrivacyPage = () => {
  return (
    <>
      <SiteHeadMetadata title="Privacy Policy" />
      <PrivacyPolicy />
      <BottomNav />
    </>
  )
}

export default PrivacyPage
